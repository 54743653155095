import _ from 'lodash';
import {AnyObject} from '~@core/type/Common';

export default {
  wrap(url: string): string {
    return url;
  },
  getServerInfo(): Record<string, unknown> {
    return {
      rootPath: '/',
      rootUrl: null, // 'http://127.0.0.1:8000',
    };
  },
  toUrlString(url: string | undefined, params: Record<string, unknown> | unknown | null = null, domain: string | null = null): string {
    const uri = this.getUrl(url);
    if (params !== null && params !== undefined) {
      if (_.isArray(params)) {
        if (!_.isEmpty(params)) {
          const pathName = _.trimEnd(uri.pathname, '/');
          uri.pathname = `${pathName}/${_.values(params).join('/')}`;
        }
      } else if (_.isObject(params)) {
        if (!_.isEmpty(params)) {
          Object.entries(params).forEach(([key, value]) => {
            uri.searchParams.set(key, _.isObject(value) ? JSON.stringify(value) : value);
          });
        }
      } else {
        const pathName = _.trimEnd(uri.pathname, '/');
        uri.pathname = `${pathName}/${params}`;
      }
    }
    return uri.toString();
  },
  toPathString(path: string, params: Record<string, unknown> | unknown | null = null): string {
    const uri = this.getUrl(path);
    if (params !== null && params !== undefined) {
      if (_.isArray(params) && !_.isEmpty(params)) {
        const pathName = _.trimEnd(uri.pathname, '/');
        uri.pathname = `${pathName}/${_.values(params).join('/')}`;
      } else if (_.isObject(params) && !_.isEmpty(params)) {
        Object.entries(params).forEach(([key, value]) => {
          uri.searchParams.set(key, _.isObject(value) ? JSON.stringify(value) : value);
        });
      } else {
        const pathName = _.trimEnd(uri.pathname, '/');
        uri.pathname = `${pathName}/${params}`;
      }
    }
    return uri.pathname + uri.search;
  },
  getUrl(url: string | undefined, domain: string | null = null): URL {
    const serverInfo = this.getServerInfo();
    const tmpOrigin: string | null = window === undefined ? (serverInfo.rootUrl as string | null) : (domain || window.location.origin);
    let tmpUrl: string | undefined = url;
    if (!url) {
      tmpUrl = window === undefined ? (serverInfo.url as string | undefined) : window.location.href;
    }
    return new URL(tmpUrl || '', tmpOrigin || undefined);
  },
  concat(first: string, ...slugs: string[]): string {
    let path = _.chain(first).trimStart().trimEnd('/ ').value();
    if (slugs) {
      const tail = _.chain(slugs).map((slug) => _.trim(slug, '/ ')).join('/').value();
      if (tail) {
        path = `${path}/${tail}`;
      }
    }
    return path;
  },
  query(code?: string | string[]): string | AnyObject {
    if (typeof window !== 'undefined') {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      )
      if (_.isObject(code)) {
        return _.pick(params, code);
      }
      if (code) {
        return params[code] as string;
      }
      return params
    }
    return {};
  }
};