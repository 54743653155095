import React, {createContext, PropsWithChildren, useMemo,} from 'react';
import AppContextType from '~@app/component/app/AppContextType';
import {useRouter} from 'next/router';

export const AppContext = createContext<AppContextType | null>(null);

export interface AppProviderProps {
  domain?: string
}

const AppProvider = ({domain = 'giapha', children}: PropsWithChildren<AppProviderProps>): React.ReactElement => {

  const router = useRouter();

  const contextValue = useMemo(() => new AppContextType(router, domain), [domain]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;