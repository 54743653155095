import _ from 'lodash'
import moment from 'moment'
import {Url} from 'next/dist/shared/lib/router/router'
import {NextRouter} from 'next/router'
import {AnyObject, AnyString} from '~@core/type/Common'
import {trans} from '~@core/util/StringUtil'
import urlUtil from '~@core/util/UrlUtil'

export default class AppContextType {
  private readonly router: NextRouter


  private domain: string

  constructor(router, domain) {
    this.router = router
    this.domain = domain
  }

  redirect(path, params: AnyObject = null) {
    window.location.href = urlUtil.toUrlString(path, params)
  }

  push(url: Url, as?: Url, options?) {
    return this.router.push(url, as, options)
  }


  async navigate(path, params: string | number | AnyObject = null, replace = false) {
    const url = urlUtil.toPathString(path, params)
    if (replace) {
      return this.router.replace(url, url)
    }
    return this.router.push(url, url)
  }

  toDateString(datetime) {
    return datetime ? moment(datetime).format('DD-MM-YYYY') : null
  }

  toDateTimeString(datetime) {
    return datetime ? moment(datetime).format('DD-MM-YYYY HH:mm') : null
  }

  navigateHome(params = null, replace = false) {
    this.navigate('/', params, replace)
  }

  newTab(path, params) {
    let url = urlUtil.toPathString(path, params)
    const pathName = window.location.pathname
    if (_.startsWith(url, '#')) {
      url = urlUtil.concat(pathName, url)
    }
    window.open(urlUtil.toUrlString(url), '_blank')
  }

  mediaUrl(url: string, domain: AnyString = null): string {
    return urlUtil.toUrlString(url, null, domain || this.domain)
  }

  url(path, params: AnyObject = null) {
    return urlUtil.toUrlString(path, params)
  }

  homeUrl(params: AnyObject = null) {
    return this.url('/', params)
  }

  back(linkIfFailUrl?: string): boolean {
    if (document.referrer) {
      const isSameDomain = new URL(document.referrer).origin.toLowerCase() === window.location.origin.toLowerCase()
      if (isSameDomain) {
        window.history.back()
        return true
      }
    }

    if (linkIfFailUrl) {
      window.location.href = linkIfFailUrl
      return false
    }

    return false
  }

  toMoney(value, currency: unknown = null, locale = 'vi') {
    let money: string | number = parseFloat(value)
    if (!money) {
      money = 0
    }
    money = money.toLocaleString(locale)
    return `${money || 0} ${currency || 'VND'}`
  }

  toGender(value: string) {
    const gender = value.toLowerCase()
    if (gender === 'male') {
      return trans('male')
    }
    if (gender === 'female') {
      return trans('female')
    }
    return trans('other')
  }
};